import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';

 import "../src/assets/plugins/nucleo/css/nucleo.css";
 import "@fortawesome/fontawesome-free/css/all.min.css";
import "../src/assets/scss/argon-dashboard-react.scss";
import './App.scss';

import AuthComponent from "./components/Auth/Login/AuthComponent";
import Failure from './components/BankCard/failure';
import Success from './components/BankCard/success';
import ForgetPwd from './components/Auth/forgetPwd';
import ResetPwd from './components/Auth/resetPwd';

const loading = () => <div className="animated fadeIn pt-3 text-center">chargement...</div>;

// Containers
 const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const Home = React.lazy(() => import('./views/Home/Home'));

const Login = React.lazy(() => import('./components/Auth/Login/Login'));
const Register = React.lazy(() => import("./components/Auth/Register/index"));

class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Route exact path="/payment/:id" component={PaymentBill} />, */}
            <Route
              exact
              path="/Login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forget-pwd"
              name=" Page"
              render={(props) => <ForgetPwd {...props} />}
            />
             <Route
              exact
              path="/reset-pwd"
              name="Reset Page"
              render={(props) => <ResetPwd {...props} />}
            />

            <Route
              exact
              path="/Register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              path="/khallasli"
              name="Home"
              render={(props) => <Home {...props} />}
            />
            <AuthComponent>
              <Route
                path="/"
                name="dashboard"
                render={(props) => <DefaultLayout {...props} />}
              />
            </AuthComponent>
            {/*  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
