import { BASE_URL, BASE_URL_DEV } from "../config.dev";
export const post_url_authentification = () => {
  return BASE_URL + "/api/auth/signin";
};
export const post_url_inscription = () => {
  return BASE_URL + "/api/auth/signup";
};

export const post_forget_pwd = () => {
  return BASE_URL + "/api/user/forgot_password";
};



export const get_url_paymee = () => {
  return BASE_URL + "/api/paymee/account_name";
};

export const get_all_services =()=>{
  return BASE_URL + "/api/service";
}
export const post_payment_onea =()=>{
  return BASE_URL + "/api/onea/payment";
}
export const post_transfert_moov =()=>{
  return BASE_URL + "/api/moov/transfer";
}
export const get_all_transfert_history = () => {
  return BASE_URL + "/api/moov/transaction/by_user";
};
export const get_url_facture_history = () => {
  return BASE_URL + "/api/onea/transaction/by_user";
};


export const get_url_statistcs = () => {
  return BASE_URL + "/api/stats-by-user";
};
export const get_user_by_id = (id) => {
  return BASE_URL + `/api/user/${id}`;
};
export const update_user = (id) => {
  return BASE_URL + `/api/user/${id}`;
};


export const reset_password = (id) => {
  return BASE_URL + "/api/user/reset_password";
};
 export const change_pwd = ()=>{
   return BASE_URL + "/api/user/change_password"
 }

 export const get_feed_type = ()=>{
  return BASE_URL + "/api/type-feed"
}

export const post_url_virement_versement=()=>{
  return BASE_URL + "/api/demand-feed"
}
export const get_url_virement_versement=()=>{
  return BASE_URL + "/api/demand-feed/byUser"
}
export const post_url_topup =()=>{
  return BASE_URL +"/api/topup"
}
export const get_filter_transaction_topup= ()=>{
  return BASE_URL +"/api/topup/transaction/byUser"
}