export const getJwt = () => {
  return "Bearer " + localStorage.getItem("Auth-jwt-token");
};

export const getToken = () => {
  return localStorage.getItem("Auth-jwt-token");
};

export const getImeiFromLocalStotage = () => {
  return localStorage.getItem("imei");
};
export const getUserName = () => {
  return localStorage.getItem("nom");
};
export const getUserNum = () => {
  return localStorage.getItem("num_telephone");
};
export const FormatSolde = (props) => {
  var sld = props.toString();
  var position = sld.length - 3;
  var dt = sld.substr(0, position);
  var mm = sld.substr(position, sld.length);
  var sold = dt + "." + mm + " DT";
  return sold;
};

export const uniqueID = () => {
  function chr4() {
    return Math.random().toString(16).slice(-4);
  }
  return (
    chr4() +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    chr4() +
    chr4()
  );
};
export const GetByState = (props) => {
  if (props == "0") return "Echec";
  if (props == "1") return "Success";
  
};
export const GetByStatewithPending = (props) => {
  if (props == "2") return "Echec";
  if (props == "3") return "Success";
  if (props == "1") return "En progress";
};
export const GetTypeTopup = (props) => {
  if (props == "Recharge_G") return "En Gros";
  if (props == "Recharge_D") return "En Details";
};
export const GetErrorMessage = (props) => {
  switch (props) {
    case "Err002":
      return "Achat echouée , stock insuffisant ... ";
      break;
    case "Err004":
      return "Operation echouée , solde insuffisant.. ";
      break;
    case "Err010":
      return "Operation echouée , verifier votre numéro ou opérateur ";
      break;
    case "Err014":
      return "Operation echouée , verifier votre numéro ou opérateur ";
      break;
    case "mnt:0":
      return "Operation echouée , vérifier votre solde ";
      break;
    case "Err03":
      return "Liste vide";
      break;
    case "Err02":
      return "Erreur connexion";
      break;
    case "Err012":
      return "Erreur connexion";
      break;
    case "Err016":
      return "Operation echouée , vérifier votre solde ";

    default:
      return "Une erreur s'est produite, veuillez réessayer ultérieurement ...";
  }
};

export const HowTochargeTickets = (props) => {
  if (props == "Ooredoo" || props == "Op1")
    return "taper *101* code de recharge # ";
  if (props == "Orange" || props == "Op2")
    return "taper *100* code de recharge # ";
  if (props == "Telecom" || props == "Op3")
    return "taper *123* code de recharge # ";
};
export const HowRechargeTypeIs = (props) => {
  if (props == "Gros") return "Recharge_G";
  if (props == "Détail") return "Recharge_D";
};
export const OpCode = (props) => {
  if (props == "moov") return "Op1";
  if (props == "orange") return "Op2";
  if (props == "telecel") return "Op3";
};
export const CodeToOp = (props) => {
  if (props == "Op1") return "MOOV";
  if (props == "Op2") return "Orange";
  if (props == "Op3") return "Telecel";
};

export const convertmontant = (props) => {
  if (props == "1000") return "1";
  if (props == "5000") return "5";
  if (props == "10000") return "10";
};

export const HowToGetSoldeTickets = (props) => {
  if (props == "Ooredoo") return "taper *100* pour consulter solde ";
  if (props == "Orange") return "taper *101* pour consulter solde ";
  if (props == "Telecom") return "taper *122* pour consulter solde ";
};
export const logoOperateur = (props) => {
  if (props == "Ooredoo")
    return process.env.PUBLIC_URL + "/images/recharge/onatel.jpg";
  if (props == "Orange")
    return process.env.PUBLIC_URL + "/images/recharge/orange.svg";
  if (props == "Telecom")
    return process.env.PUBLIC_URL + "/images/recharge/telecel.jpg";
};
export const ColorOperateur = (props) => {
  if (props == "Ooredoo") return "red";
  if (props == "Orange") return "#ec7731";
  if (props == "Telecom") return "#0861d2";
};
export const logoOrganisme = (props) => {
  if (props == "1" || props == "0701")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/sonabel.jpg";
  if (props == "2" || props == "0502")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/onea.jpg";
    if (props == "3" || props == "0502")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/moov.jpg";
  // if (props == "3" || props == "0206")
  //   return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/cnss.jpg";
  if (props == "4" || props == "0151"||
    props == "0152" ||
      props == "0153" ||
      props == "0154" ||
      props == "0380" ||
      props == "0381" ||
      props == "0382" ||
      props == "0383" ||
      props == "0384"||
      props=="0385")
  
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/tt.jpg";
  if (props == "5" || props == "0737" || props == "0763")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/orange.jpg";
  if (props == "6" || props == "0777")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/ooredoo.jpg";
  if (props == "7" || props == "0770")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/topnet.jpg";
  if (props == "8")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/globalnet.jpg";
  if (props == "9")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/cfe.jpg";
  if (props == "10")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/advans.jpg";
  if (props == "11")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/tayssir.jpg";
  if (props == "12")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/snit.jpg";
  if (props == "13")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/microcreo.jpg";
  if (props == "14")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/ttn.jpg";
  if (props == "15")
    return process.env.PUBLIC_URL + "/images/LogosPaymentFacture/zitouna.jpg";
};

export const NameOrganisme = (props) => {
  if (props == "1" || props == "0701") return "SONABEL";
  if (props == "2" || props == "0502") return "ONEA";
  if (props == "4" || props == "0151"||
    props == "0152" ||
      props == "0153" ||
      props == "0154" ||
      props == "0380" ||
      props == "0381" ||
      props == "0382" ||
      props == "0383" ||
      props == "0384"||
      props=="0385")
    return "TUNISIE TELCOM";
  if (props == "5" || props == "0737" || props == "0763") return "ORANGE";
  if (props == "6" || props == "0777") return "OOREDOO";
  if (props == "7" || props == "0770") return "TOPNET";
  if (props == "8") return "GLOBAL NET";
  if (props == "9") return "CFE";
  if (props == "10") return "ADVANS";
  if (props == "11") return "TAYSSIR";
  if (props == "12") return "SNIT";
  if (props == "13") return "MICROCREO";
  if (props == "14") return "TTN";
  if (props == "15") return "ZITOUNA";
};
export const CodeOrganisme = (props) => {
  if (props == "1") return ["0701"];
  if (props == "2") return ["0502"];
  if (props == "3") return ["0206"];
  if (props == "4")
    return [
      "0151",
      "0152",
      "0153",
      "0154",
      "0380",
      "0381",
      "0382",
      "0383",
      "0384",
      "0385"
    ];
  if (props == "5") return ["0737", "0763"];
  if (props == "6") return ["0777"];
  if (props == "7") return ["0770"];
  // if(props == "8")  return "";
  // if(props == "9")  return "";
  // if(props == "10")  return "";
  // if(props == "11")  return "";
  // if(props == "12")  return "";
  // if(props == "13")  return "";
  // if(props == "14")  return "";
  // if(props == "15")  return "";
};

export const Codebank = (props) => {
  if (props == "Africa") return "Banque de l'Afrique";
  if (props == "Union") return "Banque de l'Union";
  if (props == "Atlantic") return "Banque Atlantique";
  if (props == "Habitat") return "Banque de Aabitat";
  if (props == "SocieteGen") return "Sociéte Générale";
};
