import React, { Component } from "react";
import { Row, Col, Button, Form } from "reactstrap";
import './style.css';
import Axios from "axios";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import queryString from "query-string";
import { getImeiFromLocalStotage, getJwt } from "../../helpers";
var currentdate = new Date();
currentdate.setMinutes( currentdate.getMinutes()+10 )
var curday = function (sp) {
  //today = new Date();
  var dd = currentdate.getDate();
  var mm = currentdate.getMonth() + 1; //As January is 0.
  var yyyy = currentdate.getFullYear();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return yyyy + sp + mm + sp + dd;
};
class Success extends Component {
  constructor(props) {
    super(props);
    const values= queryString.parse(this.props.location.search)
    this.initialState ={
      amount:0.0,
      currency:null,
      formUrl:null,
      orderId:values.orderId,
      orderStatus:null,
      numCard:null,
      ErrorCode:null,
      ErrorMessage:null,
      numAutorisation:null,
      orderNumber:parseInt(Math.random()*1000000000, 10),
      done: false,
      datetime:curday("-")+ "T" + currentdate.getHours() + ":" 
      + currentdate.getMinutes()+ ":" + currentdate.getSeconds()
    }
    this.state = this.initialState;
    this.saveTransaction = this.saveTransaction.bind(this);
    this.getOrderStatus = this.getOrderStatus.bind(this);
  }
  saveTransaction(er){
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('orderId');
    Axios({
      method: "post",
      url: "https://click-to-pay.khallasli.com/api/clictopay",
      headers: {
        "Content-Type": "application/json",
        "Access-Origin": "*",
      },
      data: {
        imei: getImeiFromLocalStotage(),
        orderStatus: er.OrderStatus,
        numCard: er.Pan,
        ErrorCode: er.ErrorCode,
        ErrorMessage: er.ErrorMessage,
        numAutorisation: er.approvalCode,
        orderNumber: er.OrderNumber,
        amount:er.Amount,
        currency:er.currency,
        cardholderName:er.cardholderName,
        orderId:myParam
      },
    }).then(res => {
      this.setState({ done: true });
      window.location = "/#/dashboard"
    })
    .catch(function (error) {})
  }
  getOrderStatus(req){
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('orderId');
    //console.log("const myParam = urlParams.get('orderId')",myParam); 
    Axios({
      method: "get",
      url: "https://ipay.clictopay.com/payment/rest/getOrderStatus.do",
      params: {
        userName:"100090525",
        password:"45AmQ2txW",
        language:"fr",
        orderId:myParam, 
      },
    })
    .then(async(res)  => {
        //console.log("res.data.ErrorCode",res.data.ErrorCode)
        const er=res.data
        this.saveTransaction(er)
    })
    .catch(function (error) {
      if (error.message == "Request failed with status code 401") {
        Swal.fire({
          icon: "info",
          title: "Session expirée!",
          text: "Reconnecter",
        }).then((value) => {
          window.location = "/#/Login";
        });
      } else {
        Swal.fire({
          icon: "info",
          title: "Problème serveur !",
          text: "Essayer ultérieureent",
        }).then((value) => {
          window.location.reload();
        });
      }
    });
  }
  render() {
    return (
      <div>
          
        {  Swal.fire({
            title: "Opération terminé avec succès",
            text:"Votre alimentation a succès",
            icon: "success",
          }).then((value) => {     
            this.getOrderStatus()
          })}
       
      </div>
    );
  }
}
export default Success 